body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-default {
  color: #fff;
  text-shadow: none;
  /* Prevent inheritence from `body` */
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

.btn-default:hover,
.btn-default:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
}

body {
  background: #000; /*url(../img/cover.jpg) no-repeat center center fixed;*/
  background-size: cover;
  color: #fff;
  text-align: center;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
    position:absolute;
  display: table;
  width: 100%;
  height: 100%;
  /* For at least Firefox */
  min-height: 100%;
  background: rgba(48, 53, 70, 0.5);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#particles-container {
    position:absolute;
    width: 100%;
  height: 100%;
  opacity: 0.8;
    /* background: linear-gradient(141deg, #764ba2 0%, #09203f 75%); */
  z-index: 0;
}

.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}

.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

/*
 * Header
 */
.masthead-brand {
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-masthead {
  text-align: center;
  display: block;
}

.nav-masthead .nav-link {
  display: inline-block;
}

@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

/*
* Cover
*/
.cover {
padding: 0 20px;
}

.cover .btn-notify {
padding: 10px 40px;
font-weight: 500;
text-transform: uppercase;
border-radius: 5px;
cursor: pointer;
font-size: 15px;
opacity: 0.6
}

.cover-heading {
font-weight: 500;
text-transform: uppercase;
letter-spacing: 10px;
font-size: 2rem;
margin-bottom: 4rem;
opacity: 0.8;
transition: all 3s ease-out;
text-align: center;
}

.breathe{
-o-opacity: 0.8;
-webkit-opacity: 0.8;
opacity: 0.8;
/* animation: pulsate 10s ease-out infinite; */
filter: blur(0px);
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently
                      supported by Chrome and Opera */
}

.breatheOut{
-o-opacity: 0;
-webkit-opacity: 0;
opacity: 0;
transition: all 2s ease-out;
filter: blur(5px);
}

@media (min-width: 768px) {
.cover-heading {
font-size: 3.4rem;
letter-spacing: 15px;
margin: 0 auto;
}
}

.cover-copy {
max-width: 500px;
margin: 0 auto 3rem;
}
.lead{
margin-top:30px
}
.form-v4-content  {
background: #fff;
width: 850px;
border-radius: 10px;
-o-border-radius: 10px;
-ms-border-radius: 10px;
-moz-border-radius: 10px;
-webkit-border-radius: 10px;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
-o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
margin: 5% auto;
position: relative;
display: flex;
display: -webkit-flex;
font-family: 'Open Sans', sans-serif;
text-align: left
}
.form-v4-content h2 {
font-weight: 700;
font-size: 30px;
padding: 6px 0 0;
margin-bottom: 34px;
}
.form-v4-content .form-left {
background: linear-gradient(141deg, #764ba2 0%, #09203f 75%);
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
padding: 20px 40px;
position: relative;
width: 50%;
color: #fff;
}
.form-v4-content .form-left p {
font-size: 15px;
font-weight: 300;
line-height: 1.5;
}
.form-v4-content .form-left span {
font-weight: 700;
}
.form-v4-content .form-left .text-2 {
margin: 20px 0 25px;
}
.form-v4-content .form-left .account {
background: #fff;
border-radius: 5px;
width: 180px;
border: none;
margin: 15px 0 50px 0px;
cursor: pointer;
color: #333;
font-weight: 700;
font-size: 15px;
font-family: 'Open Sans', sans-serif;
appearance: unset;
-moz-appearance: unset;
-webkit-appearance: unset;
-o-appearance: unset;
-ms-appearance: unset;
outline: none;
-moz-outline: none;
-webkit-outline: none;
-o-outline: none;
-ms-outline: none;
}
.form-v4-content .form-left .account:hover {
background: #e5e5e5;
}
.form-v4-content .form-left .form-left-last input {
padding: 15px;
}
.form-v4-content .form-detail {
padding: 20px 40px;
position: relative;
width: 50%;
}
.form-v4-content .form-detail h2 {
color: #3786bd;
}
.form-v4-content .form-detail .form-group {
display: flex;
display: -webkit-flex;
margin:  0 0px;
}
.form-v4-content .form-detail .form-row {
width: 100%;
position: relative;
}
.form-row{
margin:0;
}
.form-v4-content .form-detail .form-group .form-row.form-row-1 {
width: 50%;
padding: 0 16px 0 0;
margin: 0;
}
.form-v4-content .form-detail .form-group .form-row.form-row-1:first-child{
padding-left: 0px
}
.form-v4-content .form-detail .form-group .form-row.form-row-1:last-child{
padding-right: 0px
}
.form-v4-content .form-detail label {
font-weight: 600;
font-size: 15px;
color: #666;
display: block;
margin-bottom: 8px;
}
.form-v4-content .form-detail .form-row label#valid {
position: absolute;
right: 20px;
top: 34%;
transform: translateY(-50%);
-o-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-ms-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
width: 14px;
height: 14px;
border-radius: 50%;
-o-border-radius: 50%;
-ms-border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
background: #53c83c;
left: unset;
display: none !important
}
.form-v4-content .form-detail .form-row label#valid::after {
content: "";
position: absolute;
left: 5px;
top: 2px;
width: 3px;
height: 8px;
border: 1px solid #fff;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
-o-transform: rotate(45deg);
-moz-transform: rotate(45deg);
transform: rotate(45deg);
}
.form-v4-content .form-detail .form-row label.error {
padding-left: 0;
margin-left: 0;
display: block;
position: absolute;
bottom: -2px;
width: 100%;
background: none;
color: red;
font-weight: 400;
font-size: 12px;
top: unset
}
.form-v4-content .form-detail .form-row label.error::after {
/* content: "\f343"; */
font-family: "LineAwesome";
position: absolute;
transform: translate(-50%, -50%);
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
-o-transform: translate(-50%, -50%);
-moz-transform: translate(-50%, -50%);
right: 16px;
top: -30px;
color: red;
font-size: 18px;
font-weight: 900;
}
.form-v4-content .form-detail .input-text {
margin-bottom: 27px;
z-index: 1;
background: transparent;
}
.form-v4-content .form-detail input {
width: 100%;
padding: 11.5px 15px;
border: 1px solid #e5e5e5;
border-radius: 5px;
appearance: unset;
-moz-appearance: unset;
-webkit-appearance: unset;
-o-appearance: unset;
-ms-appearance: unset;
outline: none;
-moz-outline: none;
-webkit-outline: none;
-o-outline: none;
-ms-outline: none;
font-family: 'Open Sans', sans-serif;
font-size: 15px;
color: #333;
box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
-o-box-sizing: border-box;
-ms-box-sizing: border-box;
}
.form-v4-content .form-detail .form-row input:focus {
border: 1px solid #53c83c;
}
.form-v4-content .form-detail .form-checkbox {
margin-top: 1px;
position: relative;
}
.form-v4-content .form-detail .form-checkbox input {
position: absolute;
opacity: 0;
}
.form-v4-content .form-detail .form-checkbox .checkmark {
position: absolute;
top: 2px;
left: 0;
height: 15px;
width: 15px;
border: 1px solid #ccc;
cursor: pointer;
}
.form-v4-content .form-detail .form-checkbox .checkmark::after {
content: "";
position: absolute;
left: 5px;
top: 1px;
width: 3px;
height: 8px;
border: 1px solid #3786bd;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
-o-transform: rotate(45deg);
-moz-transform: rotate(45deg);
transform: rotate(45deg);
display: none;
}
.form-v4-content .form-detail .form-checkbox input:checked ~ .checkmark::after {
display: block;
}
.form-v4-content .form-detail .form-checkbox p {
margin-left: 10px;
color: #333;
font-size: 14px;
font-weight: 600;
text-align: left;
}
.form-v4-content .form-detail .form-checkbox .text {
font-weight: 700;
color: #3786bd;
text-decoration: underline;
}
.form-v4-content .form-detail .register {
background: #3786bd;
border-radius: 5px;
width: 130px;
border: none;
margin: 6px 0 10px 0px;
cursor: pointer;
color: #fff;
font-weight: 700;
font-size: 15px;
}
.form-v4-content .form-detail .register:hover {
background: #2f73a3;
}
.form-v4-content .form-detail .form-row-last input {
padding: 12.5px;
}
label{
-o-transition: 200ms all ease-in-out;
-webkit-transition: 200ms all ease-in-out;
transition: 200ms all ease-in-out;
}
.form-row label:not(.error) {
position: absolute;
top: 12px;
left: 10px;
z-index:0
}
.form-row input:focus + label:not(.error),
.form-row input:focus + label + label:not(.error),
.form-row.filled label:not(.error){
top: -8px;
font-size: 12px;
background: #fff;
padding: 0 5px;
z-index:2
}
.input-file{
overflow: hidden;
position: absolute;
z-index: -1;
opacity: 0
}
.form-row.filled .input-file + label,
.form-row input.input-file:focus + label:not(.error),
.form-row .input-file + label{
padding: 11.5px 15px;
border: 1px solid #e5e5e5;
top: 0;
left: 0;
width: 100%;
border-radius: 5px;
font-size: 15px;
}
/* Responsive */
@media screen and (max-width: 991px) {
.form-v4-content {
margin: 180px 20px;
flex-direction:  column;
-o-flex-direction:  column;
-ms-flex-direction:  column;
-moz-flex-direction:  column;
-webkit-flex-direction:  column;
}
.form-v4-content .form-left {
width: auto;
border-top-right-radius: 10px;
border-bottom-left-radius: 0;
}
.form-v4-content .form-detail {
padding: 30px 20px 30px 20px;
width: auto;
}
}
@media screen and (max-width: 575px) {
.form-v4-content .form-detail .form-group {
flex-direction: column;
-o-flex-direction:  column;
-ms-flex-direction:  column;
-moz-flex-direction:  column;
-webkit-flex-direction:  column;
margin: 0;
}
.form-v4-content .form-detail .form-group .form-row.form-row-1 {
width: 100%;
padding:  0;
}
}

@keyframes pulsate {
0%, 20% {
transform: scale(1);
filter: blur(5px);
}
50%, 70% {
transform: scale(1);
filter: none;
}
100% {
transform: scale(1);
filter: blur(5px);
}
}
/*
* Affix and center
*/
@media (min-width: 768px) {
/* Pull out the header and footer */
.masthead {
position: fixed;
top: 0;
}
.mastfoot {
position: fixed;
bottom: 0;
}
/* Start the vertical centering */
.site-wrapper-inner {
vertical-align: middle;
}
/* Handle the widths */
.masthead,
.mastfoot,
.cover-container {
width: 100%;
/* Must be percentage or pixels for horizontal alignment */
}
}
.select2-container{
  width: 100% !important;
  height: 47px;
}
.select2-container .select2-selection--single {
  height: 47px;
}
.select2-results__option{
  color: #09203f;
  text-align: left
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 45px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 10px;
}
.select2{
  margin-bottom: 30px;
  z-index:1;
}
:focus{
  outline: transparent auto 0px;
}

.select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
background-color: #eeeeee4d;
}